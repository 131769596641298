<template>
  <div>
    <b-spinner v-if="loading" variant="secondary" class="my-3"></b-spinner>
    <div v-else>
      <h2 class="mt-3">{{ offer.opportunity.name }}</h2>
      <h5 class="mt-2 mb-4">{{ offer.job.name }}</h5>
      <messages-list id="messagesList" :error-messages="errorsList" />
      <div v-if="!shouldDisplayMessages">
        <b-row class="border-bottom py-2">
          <b-col>
            <h4>{{ $t('service') }}</h4>
          </b-col>
        </b-row>
        <b-row class="border-bottom py-3">
          <b-col cols="12" lg="4" class="text-muted">
            {{ $t('order-number') }}:
          </b-col>
          <b-col cols="12" lg="8">{{ offer.job.name }}</b-col>
        </b-row>
        <b-row class="border-bottom py-3">
          <b-col cols="12" lg="4" class="text-muted">
            {{ $t('offer-details.opportunity') }}:
          </b-col>
          <b-col cols="12" lg="8">
            <strong>{{ offer.opportunity.name }}</strong>
          </b-col>
        </b-row>
        <b-row class="border-bottom py-3">
          <b-col cols="12" lg="4" class="text-muted">
            {{ $t('price-net') }}:
          </b-col>
          <b-col cols="12" lg="8">
            <!-- <s class="text-muted mr-1" v-if="firstCancelationWithPriceChange">
              {{
              firstCancelationWithPriceChange.old_price_purchasing | currency
              }}
            </s> -->
            <strong>
              {{ offer.job.service_price_purchasing_sum | currency }}
            </strong>
            <template v-if="firstCancelationWithPriceChange">
              ({{
                $t('change-info', {
                  num: cancelationsWithPriceChange.length,
                  date: $moment(offer.cancellations[0].created_date).format(
                    'lll',
                  ),
                })
              }})
            </template>
          </b-col>
        </b-row>
        <b-row class="border-bottom py-3">
          <b-col cols="12" lg="4" class="text-muted">
            {{ $t('install-time') }}:
          </b-col>
          <b-col cols="12" lg="8">{{ serviceTotalTime }}</b-col>
        </b-row>
        <b-row class="border-bottom py-3" v-if="offer.job.date_expected">
          <b-col cols="12" lg="4" class="text-muted">
            {{ $t('inputs.headings.delivery_date') }}:
          </b-col>
          <b-col cols="12" lg="8">
            {{ offer.job.date_expected | moment('L') }}
          </b-col>
        </b-row>
        <b-row class="border-bottom py-3">
          <b-col cols="12" lg="4" class="text-muted">{{ $t('remark') }}:</b-col>
          <b-col cols="12" lg="8">
            <comments :job-api-id="offer.api_id" :offer="true" />
          </b-col>
        </b-row>
        <b-row class="border-bottom py-3" v-if="offer.job.professional_comment">
          <b-col cols="12" lg="4" class="text-muted">
            {{ $t('professional_comment_customer') }}:
          </b-col>
          <b-col cols="12" lg="8">
            <div v-html="offer.job.professional_comment" style=""></div>
          </b-col>
        </b-row>
        <b-row class="border-bottom py-3">
          <b-col cols="12" lg="4" class="text-muted">{{ $t('tasks') }}:</b-col>
          <b-col cols="12" lg="8">
            <services-preview
              :services="offer.services"
              :custom_services="offer.custom_services"
              :opportunity-id="offer.opportunity.sfid"
              :current-price-purchase="offer.job.service_price_purchasing_sum"
              :use-price-purchase="true"
              :show-value-entry="true"
              :showPricePurchase="true"
            />
          </b-col>
        </b-row>

        <b-row
          class="border-bottom py-3"
          v-if="offer.opportunity.documentation_type"
        >
          <b-col cols="12" lg="8" offset-lg="4">
            {{ offer.opportunity.documentation_type }}
          </b-col>
        </b-row>
        <ProductsListInfo
          :products="offer.products"
          :context="'offers'"
          :orderSfid="job_id"
        />
        <b-row class="border-bottom py-3">
          <b-col cols="12">
            <h4>{{ $t('customer-offer') }}</h4>
            <small class="text-primary my-1">
              <i>
                <b>{{ $t('note') }}:</b>
                {{ $t('offer-detail-address-note') }}
              </i>
            </small>
          </b-col>
        </b-row>

        <b-row class="border-bottom py-3 align-items-baseline">
          <b-col cols="4" class="text-muted">{{ $t('address') }}:</b-col>
          <b-col cols="8" lg="4">
            {{ offer.customer.shipping_address.postal_code }}
            {{ offer.customer.shipping_address.city }}
          </b-col>
          <b-col cols="12" lg="4">
            <b-button
              variant="outline-primary"
              block
              target="_blank"
              class="text-uppercase"
              :href="`https://maps.google.com/?q=${offer.customer.shipping_address.city}+${offer.customer.shipping_address.postal_code}`"
            >
              {{ $t('view-on-map') }}
            </b-button>
          </b-col>
        </b-row>

        <b-row class="border-bottom py-3">
          <b-col cols="12">
            <h4>{{ $t('possible-appointments') }}</h4>
            <small class="text-primary my-1">
              <i>
                <b>{{ $t('note') }}:</b>
                {{ $t('offer-detail-accept-note') }}
              </i>
            </small>
          </b-col>
        </b-row>

        <b-row class="border-bottom py-3 gid-offer__appointment">
          <b-col cols="12" lg="8">
            <fieldset v-if="offer.job.dates && offer.job.dates.length">
              <div
                class="radio-item"
                v-for="(date, index) in offer.job.dates"
                :key="index"
              >
                <b-form-radio
                  type="radio"
                  :name="offer.job.sfid"
                  :value="index"
                  v-model="dateSelected"
                >
                  <strong>{{ date.start | moment(' dd, L') }},</strong>
                  <span>{{ date.start | moment('H:mm') }}</span>
                  -
                  <strong v-if="isMultidayPeriod(date.start, date.end)">
                    {{ date.end | moment(' dd, L') }},
                  </strong>
                  <span>{{ date.end | moment('H:mm') }}</span>
                </b-form-radio>
                <hr
                  class="mt-1 mb-2"
                  v-if="index != offer.job.dates.length - 1"
                />
              </div>
            </fieldset>
            <i v-else>
              {{
                $t(
                  offer.job.days_until_finalization_sla >= 1
                    ? 'accept-offer-sla'
                    : 'accept-offer-sla-expired',
                  {
                    days: offer.job.days_until_finalization_sla,
                  },
                )
              }}
            </i>
          </b-col>
          <b-col
            cols="12"
            lg="4"
            class="my-2 my-lg-0 d-flex flex-column align-items-stretch justify-content-center"
          >
            <accept-job
              :offer="this.offer"
              :dateSelected="this.dateSelected"
              :loading="acceptLoading"
              @accept="onAccept"
            />
          </b-col>
        </b-row>

        <h4 class="mt-3">{{ $t('offer-details.reject.title') }}</h4>
        <small class="text-primary">
          <i>{{ $t('offer-details.reject.note') }}</i>
        </small>

        <b-row class="border-bottom py-3">
          <b-col cols="12" lg="8">
            <b-form-select v-model="rejectReason" :options="rejectReasons">
              <template slot="first">
                <option :value="null" disabled>
                  --
                  {{ $t('offer-details.reject.select-placeholder') }} --
                </option>
              </template>
            </b-form-select>
            <b-form-textarea
              v-if="useRejectReasonText"
              v-model="rejectReasonText"
              :placeholder="$t('offer-details.reject.text-placeholder')"
              rows="3"
              max-rows="6"
              class="mt-2"
            ></b-form-textarea>
          </b-col>
          <b-col cols="12" lg="4" class="my-2 my-lg-0">
            <b-button
              variant="secondary"
              block
              class="text-uppercase"
              @click="rejectJob"
              :disabled="rejectReason === null"
            >
              {{ $t('offer-details.reject.button') }}
            </b-button>
          </b-col>
        </b-row>
      </div>

      <div
        v-else
        class="alert alert-success mx-auto gid-footer-note m-5"
        role="alert"
      >
        <p
          v-for="successMessage in successList"
          :key="successMessage"
          class="lead text-center mb-0"
        >
          <span v-html="successMessage"></span>
        </p>
      </div>
    </div>
    <additional-offers ref="additionalOffers" @accept="acceptOffer" />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import Comments from '@gid/vue-common/components/Comments';
import ServicesPreview from '@gid/vue-common/components/ServicesPreview.vue';
import PriceHasChanged from '@gid/vue-common/components/mixins/PriceHasChanged.js';
import AcceptJob from '@/components/AcceptJob.vue';
import ProductsListInfo from '@/components/ProductsListInfo.vue';
import checkJobStatus from '@/mixins/jobStatusCheckMixin';
import AcceptMixin from './AcceptMixin';
import AdditionalOffers from './AdditionalOffers.vue';

export default {
  mixins: [AcceptMixin, PriceHasChanged, checkJobStatus],
  components: {
    AdditionalOffers,
    ServicesPreview,
    AcceptJob,
    ProductsListInfo,
    Comments,
  },
  props: {
    job_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      offer: {
        job: {
          dates: [],
        },
        opportunity: {},
        customer: {
          shipping_address: {},
        },
      },
      dateSelected: null,
      errorsList: [],
      successList: [],
      loading: false,
      acceptLoading: false,
      rejectReasons: [],
      rejectReason: null,
      rejectReasonText: null,
      signatureData: null,
      acceptedOpportunities: [],
      shouldDisplayMessages: false,
    };
  },
  computed: {
    ...mapGetters(['locale', 'user', 'tandCalreadySigned']),
    canAcceptOffer() {
      return this.offer.job.dates.length != 0
        ? this.dateSelected !== null
        : true;
    },
    serviceTotalTime() {
      const duration = this.$moment.duration(
        this.offer.services_total_minutes,
        'minutes',
      );

      let result = [];
      if (duration.days()) {
        result.push(
          this.$moment.duration(duration.days(), 'days').format('d __'),
        );
      }
      if (duration.hours()) {
        result.push(
          this.$moment.duration(duration.hours(), 'hours').format('h __'),
        );
      }
      if (duration.minutes()) {
        result.push(
          this.$moment.duration(duration.minutes(), 'minutes').format('m __'),
        );
      }

      return result.join(' ');
    },
    hasMoreThanOneAccountToAcceptOffer() {
      return (
        (this.user?.is_partner_admin_and_master_account ||
          this.user?.is_company_owner_and_master_account) &&
        Object.entries(this.user?.children_accounts).length > 0
      );
    },
    useRejectReasonText() {
      return this.rejectReason == 'other_reason';
    },
    servicesEndpoint() {
      return `/api/partner/services/for-job/${this.offer.job.sfid}`;
    },
  },
  watch: {
    job_id() {
      this.loadData();
    },
  },
  async created() {
    await this.loadData();
    await this.fetchRejectReasons();
  },
  mounted() {
    this.startStatusCheck(this.job_id, (redirectPath) => {
      if (!redirectPath.includes(this.$route.name)) {
        this.$router.push(redirectPath);
      }
    });
  },
  methods: {
    getPriceChangeConfig() {
      return {
        job_view: this.offer,
        price_purchasing: true,
      };
    },
    onAccept(offer, dateSelected, accountId) {
      const toggleLoading = () => {
        this.acceptLoading = !this.acceptLoading;
      };
      const accept = () => {
        this.acceptOffer(offer, dateSelected, accountId, toggleLoading);
      };
      accept();
    },
    rejectJob() {
      const url = `/data-api/rejected-job`;
      const payload = {
        jobApiId: this.offer.job.api_id,
        partnerSfid: this.user?.account.id,
        reasonCategory: this.rejectReason,
        ...(this.useRejectReasonText
          ? { reasonText: this.rejectReasonText }
          : null),
      };

      const successMessage = this.$t('offer-details.reject.success');
      const errorMessage = this.$t('offer-details.reject.error');

      if (
        this.user?.is_partner_admin_and_master_account ||
        this.user?.is_company_owner_and_master_account
      ) {
        const children_accounts_ids = Object.keys(this.user?.children_accounts);
        const rejeted_job_reqs = [
          axios.post(url, payload),
          ...children_accounts_ids.map((x) =>
            axios.post(url, { ...payload, partnerSfid: x }),
          ),
        ];

        axios
          .all(rejeted_job_reqs)
          .then(
            axios.spread((...responses) => {
              if (responses.map((x) => x.status).every((x) => x == 201)) {
                this.successList.push(successMessage);
                this.$router.push({
                  name: 'offers',
                  params: { successMessage },
                });
              } else {
                this.errorsList.push(errorMessage);
              }
            }),
          )
          .catch(() => {
            this.errorsList.push(errorMessage);
          });
      } else {
        axios
          .post(url, payload)
          .then(() => {
            this.successList.push(successMessage);
            this.$router.push({ name: 'offers', params: { successMessage } });
          })
          .catch(() => {
            this.errorsList.push(errorMessage);
          });
      }
    },
    loadData() {
      this.loading = true;
      axios.get(`/api/partner/offers/${this.job_id}`).then((response) => {
        this.offer = response.data;
        this.$store.commit(
          'title',
          this.$t('order-with-number', { num: this.offer.job.name }),
        );
        this.loading = false;
      });
    },
    fetchRejectReasons() {
      axios
        .get(
          `/data-api/sf-picklist?api_names=Reject_Job_Reasons&values_only=true`,
        )
        .then((response) => {
          this.rejectReasons =
            response.data.Reject_Job_Reasons.Metadata.customValue.map(
              (item) => ({
                text: item.label,
                value: item.valueName,
              }),
            );
        });
    },
    isMultidayPeriod(start, end) {
      return !this.$moment(start).isSame(end, 'day');
    },
  },
};
</script>

<style lang="scss">
.gid-offer__appointment {
  background-color: #dbf1ed;
}
</style>
