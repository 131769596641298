<template>
  <div class="gid-partner-register-form-wrapper gid-dark-theme">
    <ApiResponse v-if="apiResponse" :apiResponse="apiResponse" />
    <template v-else>
      <template v-if="!plainLayout">
        <h1 class="lead font-weight-bold">{{ $t('signup-intro') }}</h1>
        <hr class="bg-white my-4" />
      </template>
      <ValidationObserver ref="observer" slim>
        <b-form id="signup-form" novalidate light-grey>
          <MainInputs
            :validation_context="validation_context"
            :form="form"
            :formWasSubmitted="formWasSubmitted"
            @handleFormValueUpdate="handleFormValueUpdate"
          />

          <hr class="bg-white mt-1 mb-3" />

          <Checkboxes
            :validation_context="validation_context"
            :form="form"
            @handleFormValueUpdate="handleFormValueUpdate"
          />

          <div class="actions-wrapper flex-column d-flex flex-sm-row">
            <b-button variant="primary" @click="submit()" :disabled="loading">
              <b-spinner v-if="loading" class="mr-2 gid-spinner--button" />
              {{ $t('submit') }}
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
    <div v-if="!isUpdate" class="dark-background" />
  </div>
</template>

<script>
import axios from 'axios';
import {
  CONTEXT_CREATE,
  CONTEXT_DESTROY,
  STORE_API_ERRORS,
} from '@gid/vue-common/store/validation.module/types';
import { filterNonInputErrors } from '@gid/vue-common/store/validation.module/utils';
import Checkboxes from '@/components/signup/Checkboxes.vue';
import MainInputs from '@/components/signup/MainInputs.vue';
import ApiResponse from '@/components/signup/ApiResponse.vue';

const VALIDATION_CONTEXT = 'signup';

export default {
  components: {
    Checkboxes,
    MainInputs,
    ApiResponse,
  },
  props: {
    isUpdate: { type: Boolean, default: false },
  },
  created() {
    this.$store.dispatch(`validation/${CONTEXT_CREATE}`, {
      context: VALIDATION_CONTEXT,
    });
  },
  destroyed() {
    this.$store.dispatch(`validation/${CONTEXT_DESTROY}`, {
      context: VALIDATION_CONTEXT,
    });
  },
  data() {
    return {
      validation_context: VALIDATION_CONTEXT,
      loading: false,
      form: {
        first_name: null,
        last_name: null,
        country_dial_code: null,
        phone: null,
        email: null,
        company_name: null,
        password: null,
        password_repeat: null,
        accept_tos: false,
        accept_marketing_consent: false,
      },
      apiResponse: null,
      formWasSubmitted: false,
    };
  },
  computed: {
    plainLayout() {
      return this.$route.query.plain === 'true' || this.isUpdate;
    },
    referralSource() {
      return this.$route.query.referral || null;
    },
  },
  methods: {
    async submit() {
      this.formWasSubmitted = true;
      this.error = null;
      const formIsValid = await this.$refs.observer.validate();
      if (formIsValid) {
        this.loading = true;

        if (this.isUpdate) {
          this.$emit('submit', this.form);
        } else {
          const { country_dial_code, ...rest } = this.form;
          const sanitizedNumber = this.form.phone.replace(/\s+/g, '');

          try {
            const response = await axios.put(`/api/partner/sign-up`, {
              ...rest,
              phone: sanitizedNumber,
              referral_source: this.referralSource,
            });
            this.apiResponse = response.data;
            this.$gtag.pageview(`/signup/success/`);
          } catch (error) {
            this.handleError(error);
          } finally {
            this.loading = false;
          }
        }
      }
    },
    handleFormValueUpdate(newValue, fieldName) {
      this.form[fieldName] = newValue;
    },
    handleError(error) {
      if (error.response) {
        if (error.response.status == 422) {
          this.$store.dispatch(`validation/${STORE_API_ERRORS}`, {
            context: VALIDATION_CONTEXT,
            errors: error.response.data.errors,
          });
          const nonInputErrors = filterNonInputErrors(
            error.response.data.errors,
            this.inputs.map(({ name }) => name),
          );
          if (Object.keys(nonInputErrors).length > 0) {
            this.showToast(nonInputErrors);
          }
        } else {
          this.showToast(this.$t('_errors.server.details'));
        }
      } else if (error.request) {
        this.showToast(this.$t('_errors.network.details'));
      } else {
        this.showToast(error.message);
      }
      this.form.phone = this.form.phone.replace(this.countryPhone.dialing, '');
    },
    showToast(message) {
      this.$bvToast.toast(message, {
        title: 'ERROR',
        variant: 'danger',
        noAutoHide: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@gid/vue-common/scss/_variables.scss';
@import '@/assets/scss/_variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.gid-partner-register-form-wrapper {
  background-color: $neutral-grey;
  padding: 2.5rem;
  border-radius: 1.25rem;
  color: $white;
  max-width: 37.5rem;
  width: 100%;
  margin: 0 auto;
  display: inline-block;

  & .actions-wrapper {
    gap: 0.625rem;
  }

  .dark-background {
    //hack to make the background dark without affecting the body
    box-shadow: 0 0 0 200vmax $dark-grey;
    position: absolute;
    z-index: -1;
  }
}
</style>
