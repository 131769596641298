<template>
  <b-row class="border-bottom py-3" v-if="reportingEnabled">
    <b-col cols="12" lg="3" class="text-muted">Reports:</b-col>
    <b-col cols="12" lg="9">
      <dl>
        <template v-for="report in reports">
          <dt :key="`dt-${report.id}`">
            {{ report.answers['report-date'] | moment('L') }}
          </dt>
          <dd :key="`dd-${report.id}`">
            {{ report.answers['daily-report'] }}
          </dd>
        </template>
      </dl>
      <template v-if="canSubmitReport">
        <b-collapse v-model="formVisible">
          <order-intake-forms
            elementId="new-report-iframe"
            :form="form_id"
            :locale="locale"
            :opportunitySfid="this.order.opportunity.sfid"
            :orderApiId="this.order.api_id"
            :accessToken="this.access_token"
            @submit="reportSubmited"
          />
        </b-collapse>
        <b-button
          v-if="!formVisible"
          variant="primary"
          size="sm"
          @click="formVisible = true"
        >
          {{ $t('diary.add') }}
        </b-button>
        <b-button
          v-else
          variant="secondary"
          size="sm"
          @click="formVisible = false"
        >
          {{ $t('diary.cancel') }}
        </b-button>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { CondOperator, RequestQueryBuilder } from '@dataui/crud-request';
import OrderIntakeForms from '@gid/vue-common/components/OrderIntakeForms';
import { JobStatusEnum } from '@gid/models';

export default {
  components: {
    OrderIntakeForms,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      submissions: [],
      formVisible: false,
    };
  },
  computed: {
    ...mapGetters(['locale', 'access_token']),
    form_id() {
      return this.order.opportunity.multiday_documentation_form_id;
    },
    reportingEnabled() {
      return (
        this.form_id &&
        this.$moment().isSameOrAfter(this.order.job.appointment_start_timestamp)
      );
    },
    canSubmitReport() {
      return [
        JobStatusEnum.APPOINTMENT_CONFIRMED,
        JobStatusEnum.STARTED,
        JobStatusEnum.FINISHED,
      ].includes(this.order.job.status);
    },
    reports() {
      return this.submissions.map((s) => ({
        ...s,
        answers: s.answers.reduce((all, a) => {
          all[a.question.name] = a.answer;
          return all;
        }, {}),
      }));
    },
  },
  methods: {
    reportSubmited() {
      this.formVisible = false;
      this.loadReports();
    },
    async loadReports() {
      const query = RequestQueryBuilder.create()
        .search({ 'job.id': { [CondOperator.EQUALS]: this.order.job.sfid } })
        .query();
      this.submissions = await axios
        .get(`/data-api/entity/submission/?${query}`)
        .then(({ data }) => data);
    },
  },
  created() {
    if (this.reportingEnabled) {
      this.loadReports();
    }
  },
};
</script>
