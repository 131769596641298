// src/mixins/statusCheckMixin.js
import Api from '@gid/vue-common/api';

export default {
  data() {
    return {
      checkInterval: null,
    };
  },
  methods: {
    async checkJobStatus(jobId, redirectCallback) {
      try {
        const response = await Api.get(`/api/partner/jobs-search/${jobId}`);
        if (response && response.data) {
          const results = response.data;
          if (results['open']?.count > 0) {
            redirectCallback(`/orders/open/${jobId}`);
          } else if (results['at-work']?.count > 0) {
            redirectCallback(`/orders/at-work/${jobId}`);
          } else if (results['completed']?.count > 0) {
            redirectCallback(`/orders/completed/${jobId}`);
          } else if (results['customer-signature']?.count > 0) {
            redirectCallback(`/orders/customer-signature/${jobId}`);
          } else if (results['final-confirmation']?.count > 0) {
            redirectCallback(`/orders/final-confirmation/${jobId}`);
          } else if (results['scheduled']?.count > 0) {
            redirectCallback(`/orders/scheduled/${jobId}`);
          } else if (results['waiting']?.count > 0) {
            redirectCallback(`/orders/waiting/${jobId}`);
          } else if (results['waiting-action']?.count > 0) {
            redirectCallback(`/orders/waiting-action/${jobId}`);
          } else {
            // No matching status found in the job search response => must be an offer:
            redirectCallback(`/offers/${jobId}`);
          }
        } else {
          console.error('Invalid response structure:', response.data);
        }
      } catch (error) {
        console.error('Error checking job status:', error);
      }
    },
    startStatusCheck(jobId, redirectCallback, intervalMs = 5 * 60 * 1000) {
      this.checkInterval = setInterval(() => {
        this.checkJobStatus(jobId, redirectCallback);
      }, intervalMs);
    },
    stopStatusCheck() {
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
        this.checkInterval = null;
      }
    },
  },
  beforeDestroy() {
    this.stopStatusCheck();
  },
};
